import React from 'react';
import { Link } from 'react-router-dom';
import './AddressMap.css';

import ImageComp from './../ImageComp/ImageComp';

import ContactForm from './ContactForm';

const AddressMap = () =>{
    return (
        <div className='contacts'>
            <div className='address-map-contacts'>
                <ContactForm/>
            </div>
            <div className='address-map'>
                <div className='address-map-div'>
                    <ImageComp aspectratio="1.78">
                        <div className='address-map-map-content'>
                            <iframe className='address-map-map'
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2571.4711669394374!2d8.620542830670916!3d49.871177797745176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd71466263c527%3A0xceb71682c4d60fbf!2sreLi%20Energy!5e0!3m2!1sen!2sde!4v1699452255139!5m2!1sen!2sde"
                                allowfullscreen="" 
                                loading="lazy" 
                                width="100%"
                                height="100%"
                                referrerpolicy="no-referrer-when-downgrade"/>
                        </div>
                    </ImageComp>
                    <Link to="https://maps.app.goo.gl/dyLCzPQ4Tp72wF489" className='address-map-address-content'>
                        <div className='address-map-address-content-head'>Headquarters</div>
                        <div className='address-map-address-content-content'>Robert-Bosch-Straße 7</div>
                        <div className='address-map-address-content-content'>64293 Darmstadt</div>
                        <div className='address-map-address-content-content'>Germany</div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default AddressMap;