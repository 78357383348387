
import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";
import { ImageHeader, DivisionBlock, ImageContentBlock, CareerList } from "../../components/General/General";

import DefaultHeader from '../../assets/img/default-header.jpeg';

import environment from '../../assets/img/careers/about-join-environment.jpeg';
import technology from '../../assets/img/careers/about-join-technology.jpeg';
import you from '../../assets/img/careers/about-join-you.jpeg';

import careerHeader from "../../assets/img/career-header.jpeg";

function Careers () {
    let jobs = [
        {position:"Technical Sales (m/f/d)", format:"Full-time employment", location:["Darmstadt, Germany","Luxembourg, Luxembourg","Hybrid"], link:"/careers/technical-sales-ft" },
        {position:"Cloud Engineer (m/f/d)", format:"Part-time employment", location:["Darmstadt, Germany","Hybrid"], link:"/careers/cloud-engineer-pt" },
        {position:"Embedded Engineer (m/f/d)", format:"Part-time employment", location:["Darmstadt, Germany","Hybrid"], link:"/careers/embedded-engineer-pt" },
        {position:"Open Application", format:"Open", location:["Darmstadt, Germany","Luxembourg, Luxembourg","Hybrid","Remote"], link:"/careers/open-application" },
    ]
    return(
        <>
            <MetaData 
                title="Join reLi Energy: Shaping the Future of Sustainable Energy Solutions"
                description="Explore exciting career opportunities at reLi Energy. Be part of a dynamic team that's at the forefront of transforming energy storage. Discover how your skills can contribute to our mission of optimizing battery performance for a more sustainable future."
                image={careerHeader}
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <ImageHeader 
                head1="Work with Sustainability and Innovation first"
                head2="Let’s take climate action to the next level together."
                image={careerHeader}
            />
            <DivisionBlock
                head="Working with reLi"
                contents={[
                    {head:"Be Heard",content:"New ideas are the starting point of innovation. Work in an environment where you will be heard."},
                    {head:"Remote First",content:"Find the model that works best for you. 100% remote, 100% office, or hybrid."},
                    {head:"Push the Boundaries",content:"We do things differently and we do it for our precious planet. With reLi your hard work will create a positive impact on the environment"},
                ]}
            />
            <DivisionBlock
                head="Work-place values"
                contents={[
                    {head:"For the Environment",image:environment,content:"Sustainability lies at our Core."},
                    {head:"For the Technology",image:technology,content:"Innovation is our weapon of choice."},
                    {head:"For You",image:you,content:"Autonomy and Freedom enables us to achieve more."},
                ]}
                inverse={true}
            />
            <CareerList
                jobs={jobs}
            />
            <FooterBar/>
        </>
    )
}

export default Careers;