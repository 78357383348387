import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";
import { CareerBlock } from "../../components/General/General";

import careerHeader from "../../assets/img/career-header.jpeg";

function EmbeddedEngineerPT () {
    return(
        <>
          <MetaData 
                title="Become a Embeddded Engineer in reLi Energy "
                description="Join reLi Energy's team and contribute to reshaping the landscape of energy optimization and storage."
                image={careerHeader}
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <CareerBlock
                position="Embedded Engineer - Part Time/Working Student"
                applicationid="T032"
                responsibilities={[
                    "Design and implement firnmware for embedded systems.",
                    "Troubleshoot and debug firmware issues.",
                    "Test firmware to ensure it meets performance and functional requirements.",
                    "Develop low-level drivers.",
                ]}
                requirement={[
                    "Currently pursuing a degree in Electrical, Computer Science, or a related field.",
                    "Experience with C and C++ programming languages.",
                    "Experience with python is an advantage.",
                    "Experience working with microcontrollers like STM32 and ARM and other hardware components like Battery Management System.",
                    "Experience in energy/automation protocols like MODBUS TCP/IP and MODBUS RTU.",
                    "Experience in peripheral protocols and driver developments for SPI, I2C, UART, etc.",
                    "Strong interest in embedded systems and a willingness to learn.",
                    "Strong background in software development and a deep understanding of embedded device architecture.",
                    "Excellent communication and problem-solving skills.",
                ]}
                benifits={[
                    "Wide opportunity for networking and exposure to entrepreneurship",
                    "Collaborative and inclusive work environment",
                    "Opportunities for innovation ",
                    "Flexible work hours and the ability to work remotely ",
                    "Being part of a diverse, dynamic and enthusiastic team",
                    "Startup salary and equity options",
                    "Opportunity to work on cutting-edge projects in sustainable energy.",
                    "Collaborative and innovative work environment.",
                ]}
            />
            <FooterBar/>
        </>
    )
}

export default EmbeddedEngineerPT;