import React from "react";

import NavBar from "../../../components/NavBar/NavBar";
import FooterBar from "../../../components/FooterBar/FooterBar";
import MetaData from "../../../components/MetaData/MetaData";
import { SmallImageHeader, PageTiles } from "../../../components/General/General";

import reliOptimizationAlgorithm from "../../../assets/img/reli-optimization-algorithm-header.jpeg";
import DispatchControl from "../../../assets/img/dispatch-control-header.jpeg";
import RevolutionizingBatteryOptimization from "../../../assets/img/revolutionizing-battery-optimization-header.jpeg";
import SimulationTool from '../../../assets/img/simulation-tool-header.jpg';
import BatteryAnalytics from '../../../assets/img/battery-analytics-image.jpg';
import BatteryHealthHeader from '../../../assets/img/battery-health-header.jpg';
import BatteryEnvironmentalHeader from '../../../assets/img/battery-environmental-header.png';
import GridStabilityHeader from "../../../assets/img/grid-stability-header.jpg";
import AIHeader from "../../../assets/img/ai-article-header.jpg";
import BatteryDataHeader from "../../../assets/img/battery-data-header.jpg";

import articlesheader from '../../../assets/img/articles-header.jpeg';


function Articles() {
    let tiles = [
        {
            img:BatteryDataHeader,
            head:"Why Accurate Battery Data Matters?",
            date:'Jul 2024',
            link:'/resources/articles/reliable-battery-data/'},
        {
            img:AIHeader,
            head:"Optimizing BESS: The AI Revolution",
            date:'May 2024',
            link:'/resources/articles/optimizing-bess-with-ai/'},
        {
            img:GridStabilityHeader,
            head:"Grid Stability in the Modern Energy Ecosystem",
            date:'Apr 2024',
            link:'/resources/articles/grid-stability/'},
        {
            img:BatteryEnvironmentalHeader,
            head:"BESS Environmental Challenges and Solutions",
            date:'Mar 2024',
            link:'/resources/articles/battery-environmental-challenges-and-solutions/'},

        {
            img:BatteryHealthHeader,
            head:"Battery State of Health",
            date:'Feb 2024',
            link:'/resources/articles/battery-state-of-health/'},

        {
            img:BatteryAnalytics,
            head:"Battery Analytics",
            date:'Jan 2024',
            link:'/resources/articles/battery-analytics/'},
        {
            img:SimulationTool,
            head:"Simulation Tool",
            date:'Dec 2023',
            link:'/resources/articles/simulation-tool/'},

        {
            img:RevolutionizingBatteryOptimization,
            head:"Revolutionizing Battery Optimization - a Real Success Story",
            date:'Nov 2023',
            link:'/resources/articles/revolutionizing-battery-optimization-a-real-success-story'},
        {
            img:DispatchControl,
            head:"Dispatch and Control",
            date:'Nov 2023',
            link:'/resources/articles/dispatch-and-control/'},
        {
            img:reliOptimizationAlgorithm,
            head:"Optimization Algorithm",
            date:'Oct 2023',
            link:'/resources/articles/optimization-algorithm/'},


        
    ]
    return (
        <>
            <MetaData 
                title="Insights: Exploring Innovations in Energy Storage and Optimization"
                description="Dive into reLi's collection of articles where we unpack the latest trends, innovations, and insights in energy storage and optimization. Stay informed about the future of sustainable energy solutions with our thought-provoking content."
                image={articlesheader}
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <SmallImageHeader
                image={articlesheader}
                head1='Articles'
                head2='Read the articles from reLi'
            />
            <PageTiles
                tiles={tiles}
            />
            <FooterBar/>
        </>
    );
}

export default Articles;