import React from "react";
import { ReactDOM, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXTwitter, faFacebookF, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { useCookies } from 'react-cookie';

import CookieConsent from "../CookieConsent/CookieConsent";

import './FooterBar.css'
import pageRoutes from "../../routes.js";
import socialRoutes from "../../socialroutes";

const subsectionmenu = (submenu) =>{
    return (<Link to={submenu.path} className="footer-bar-submenu-button content-small">{submenu.name}</Link>)
}

const sectionmenu = (menu) =>{
    if(menu.subfooterbar){
        const submenu = [];
        submenu.push(menu.routes.map((route,index)=>{return route.footerbar? subsectionmenu(route) : null}))
        return (
            <div className="footer-div-section">
                <Link to={menu.path} className="footer-bar-menu-button button">{menu.name}</Link>
                {submenu}
            </div>
        )
    }
}

const nonbranchsubsectionmenu = (menu) =>{
    if(menu.footerbar)
        return <Link to={menu.path} className="footer-bar-menu-button button">{menu.name}</Link>
}

const nonbranchsectionmenu = (menu) =>{
    const menus = [];
    menus.push(menu.map((route,index)=>{
        return !(route.subfooterbar)? nonbranchsubsectionmenu(route) : null}))
    return (
        <div className="footer-div-section">
            {menus}
        </div>
    )
}

const othersection = () =>{
    return (
        <div className="footer-div-section-other">
            <div className="footer-bar-social-icons">
                <Link to={socialRoutes.linkedin} className="footer-bar-social-icon"><FontAwesomeIcon icon={faLinkedinIn} size="xl" /></Link>
                <Link to={socialRoutes.twitter} className="footer-bar-social-icon"><FontAwesomeIcon icon={faXTwitter} size="xl" /></Link>
                <Link to={socialRoutes.instagram} className="footer-bar-social-icon"><FontAwesomeIcon icon={faInstagram} size="xl" /></Link>
                <Link to={socialRoutes.facebook} className="footer-bar-social-icon"><FontAwesomeIcon icon={faFacebookF} size="xl" /></Link>
            </div>
        </div>
    )
}

const FooterBar = () =>{
    const [cookies] = useCookies(["cookieConsent"]);
    const branchsections = []
    const nonbranchsections = nonbranchsectionmenu(pageRoutes)
    branchsections.push(pageRoutes.map((route,index)=>{return route.footerbar? sectionmenu(route,index) : null}));
    return (
        <>
        <div className="footer-bar">
            <div className="footer-bar-nav">
                <div className="footer-div-sections-end">
                    <Link to="/" className="footer-bar-logo">
                        <img src="https://relienergy.de/common/reLi_logo_dark.svg" alt='reLi Energy'/>
                    </Link>
                    {othersection()}    
                </div>
                <div className="footer-div-sections">{branchsections}{nonbranchsections}</div>
            </div>
            <div className="footer-bar-copyrights">
                <div className="copyright-content">© Copyright 2022 - 2024 reLi Energy GmbH. Headquaters: Darmstadt, Germany. All Rights Reserved. reLi Energy GmbH, Hilpertstraße 31, 64295, Darmstadt, Germany.</div>
                <Link to={'/terms-and-conditions'} className="copyright-button">Terms & Conditions</Link>
                <Link to={'/privacy-policy'} className="copyright-button">Privacy Policy</Link>
                <Link to={'/imprint'} className="copyright-button">Imprint</Link>
            </div>
        </div>
        {!cookies.cookieConsent && <CookieConsent />}
        </>
    )
}

export default FooterBar;