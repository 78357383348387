import React from "react";

import NavBar from "../../../components/NavBar/NavBar";
import FooterBar from "../../../components/FooterBar/FooterBar";
import MetaData from "../../../components/MetaData/MetaData";
import { SmallImageHeader, PageTiles } from "../../../components/General/General";

import awardsheader from '../../../assets/img/awards-header.jpeg';
import internationalfounders from '../../../assets/img/internationalfounders.jpeg';

function Awards() {
    let tiles = [
        {
            head:"Fit4Start",
            content:"Fit4Start",
            date:'2023',
            link:'https://www.startupluxembourg.com/fit-4-start-edition-14-selected-startups',
            img:'https://www.startupluxembourg.com/hubfs/COPERNIC/Fit4start-November2019/LUXINNOVATION_FIT4_START_BLACK.svg'
        },
        {
            head:"International Founders Award",
            content:"International Founders Award",
            date:'2023',
            link:'https://station-frankfurt.de/2023/03/24/wenn-migranten-in-deutschland-gruenden/',
            img:internationalfounders
        },
        {
            head:"AXEL Energiepokal Winner",
            content:"Axel",
            date:'2022',
            link:'https://www.axel.energy/de/news-presse/neues-bei-axel/index.php/meldung/882/neunter-demo-day-des-axel---der-energie-accelerator',
            img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLqzj30XHuCLfNANaASvzyRDwUrUnYK0YQ_YGLG1druQ&s'
        },
        {
            head:"ASML Makers Award",
            content:"TUe Contest",
            date:'2021',
            link:'https://2021.tuecontest.nl/',
            img:'https://2021.tuecontest.nl/ms-icon-150x150.png'
        },
        {
            head:"Battle of Green Talent",
            content:"EIT Innoenergy",
            date:'2021',
            link:'https://www.battleofgreentalent.com/previous-edition',
            img:'https://static.wixstatic.com/media/33d724_6e5d2d2ebd6f496e83cb70af71f5fb3d~mv2.jpg/v1/fill/w_240,h_88,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/EIT%20InnoEnergy.jpg'
        },

    ]
    return (
        <>
            <MetaData 
                title="reLi's Achievements and Recognition: Awards for Innovation in Energy Solutions"
                description="Explore the recognitions and awards reLi has earned for pioneering battery optimization and green energy solutions. Discover our journey towards excellence in sustainable technology"
                image={awardsheader}
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <SmallImageHeader
                image={awardsheader}
                head1='Awards'
                head2="Know more about reLi's recognitions"
            />
            <PageTiles
                tiles={tiles}
                contain={true}
            />
            <FooterBar/>
        </>
    );
}

export default Awards;