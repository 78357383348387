import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { SmallImageHeader, LoginDivisionBlock, ImageContentBlock } from "../../components/General/General";

import DefaultHeader from '../../assets/img/default-header.jpeg';

function Login() {
    return(
        <>
            <MetaData 
                title="Login in reLi Energy"
                description="Log in reLi Energy's Simulation Tool or Cloud Platform"
                image={DefaultHeader}
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <SmallImageHeader 
                head1="Login"
                head2=""
                image={DefaultHeader}
            />
            <LoginDivisionBlock 
                contents={[
                    {
                        head:"Battery Simulation Tool",
                        content:"Estimate the lifetime of your battery with estimation tool.",
                        button:[
                            {label:"Login",link:"https://simulation.relienergy.de"},
                        ]
                    },
                    {
                        head:"Cloud Platform",
                        content:"Visulaise your system in an digital twin with advanced analytics.",
                        button:[
                            {label:"Request Demo",link:"/resources/demo"}
                        ]
                    },
                ]}
            />
            <FooterBar/>
        </>
    )
}

export default Login;