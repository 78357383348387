import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";
import { CareerBlock } from "../../components/General/General";

import careerHeader from "../../assets/img/career-header.jpeg";

function CloudEngineerPT () {
    return(
        <>
          <MetaData 
                title="Become a Cloud Engineer in reLi Energy "
                description="Join reLi Energy's team and contribute to reshaping the landscape of energy optimization and storage."
                image={careerHeader}
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <CareerBlock
                position="Cloud Engineer - Part Time/Working Student"
                applicationid="T032"
                responsibilities={[
                    "Develop and maintain cloud application in Python with Flask as framework.",
                    "Implement REST API from cloud application with IoT core.",
                    "Collaborate with cross-functional teams to design, develop, and deploy robust and scalable solutions.",
                    "Contribute to database management and server maintenance for seamless project operation.",
                ]}
                requirement={[
                    "Currently pursuing a degree in Electrical, Computer Science, or a related field.",
                    "Proven expertise in Python application with flask architecture.",
                    "Solid understanding of database management and server maintenance.",
                    "Experience in creating and maintaining scalable cloud applications with serverless containers and docker.",
                    "Strong problem-solving and communication skills.",
                ]}
                benifits={[
                    "Wide opportunity for networking and exposure to entrepreneurship",
                    "Collaborative and inclusive work environment",
                    "Opportunities for innovation ",
                    "Flexible work hours and the ability to work remotely ",
                    "Being part of a diverse, dynamic and enthusiastic team",
                    "Startup salary and equity options",
                    "Opportunity to work on cutting-edge projects in sustainable energy.",
                    "Collaborative and innovative work environment.",
                ]}
            />
            <FooterBar/>
        </>
    )
}

export default CloudEngineerPT;