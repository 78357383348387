import React, { useState } from 'react';

import './CareersForm.css';

const CareersForm = ({position,application_id}) =>{
    const [applicationSubmitted, setApplicationSubmitted] = useState(false);
    const [coverLetterOpen,setCoverLetterOpen] = useState(false);
    const [coverLetterStatus, setCoverLetterStatus] = useState('');
    const [formData, setFormData] = useState({ firstname: '', lastname: '', country: '', email: '', linkedinprofile:'', cv: '', cover_letter: '', });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if(formData.cover_letter === ''){
            handleResetCoverLetter();
            return
        }

        const hubspotFormId = '293dd9e8-3f82-4882-bf4b-f8a3a3b097ea';
        const hubspotPortalId = '25674141';

        fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${hubspotFormId}`, {
            method: 'POST',
            headers:{
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                fields: [
                    { name: 'firstname', value: formData.firstname },
                    { name: 'lastname', value: formData.lastname },
                    { name: 'country', value: formData.country },
                    { name: 'email', value: formData.email },
                    { name: 'linkedinprofile', value: formData.linkedinprofile},
                    { name: 'application_id', value: application_id },
                    { name: 'cv', value: formData.cv},
                    { name: 'cover_letter', value: formData.cover_letter}
                ]
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                setFormData({firstname: '', lastname: '', country: '', email: '', linkedinprofile: '', cv: '', cover_letter: '',});
                setCoverLetterStatus('');
                setApplicationSubmitted(true);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleChangeCoverLetter = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleResetCoverLetter = (e) => {
        setCoverLetterOpen(false);
        if(formData.cover_letter === '')
            setCoverLetterStatus('Insert cover letter');
        else
            setCoverLetterStatus('Successful');
    }

    const handleSubmitCoverLetter = (e) => {
        e.preventDefault();
    }

    const coverletterupload = () => {
        setCoverLetterOpen(true);
    }

    return(
        <>
        <div className={`careers-form ${applicationSubmitted?'careers-form-inactive':'careers-form-active'}`}>
            <div className='careers-form-context head3'>Apply for the Position</div>
            <form className='careers-form-form' onSubmit={handleSubmit}>
            <div className='careers-form-form-field'>
                <div className='careers-form-label content-small'> First Name* </div>
                    <input className='careers-form-input content-form'
                    type="text"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleChange}
                    required
                    />
                <div className='careers-form-label content-small'> Last Name* </div>
                    <input className='careers-form-input content-form'
                    type="text"
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleChange}
                    required
                    />
                <div className='careers-form-label content-small'> Email* </div>
                    <input className='careers-form-input content-form'
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    />
                <div className='careers-form-label content-small'> Country* (Current Residency) </div>
                    <input className='careers-form-input content-form'
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                    />
                <div className='careers-form-label content-small'> Linkedin Profile* </div>
                    <input className='careers-form-input content-form'
                    type="url"
                    name="linkedinprofile"
                    value={formData.linkedinprofile}
                    onChange={handleChange}
                    required
                    />
                <div className='careers-form-label content-small'> Cover Letter* </div>
                    <div className='careers-form-input-coverletter'>
                        <input type="button" className='careers-form-input-coverletter-button content-form' value="Insert" onClick={coverletterupload}/>
                        <div className='careers-form-input-coverletter-content content-small'>{coverLetterStatus}</div>
                    </div>
                <div className='careers-form-label content-small'> CV (public link) </div>
                    <input className='careers-form-input content-form'
                    type="url"
                    name="cv"
                    value={formData.cv}
                    onChange={handleChange}
                    />
            </div>
            <button className='career-form-form-button-submit button button-primary' type="submit">Apply</button>
            <div className='career-form-disclaimer content-tc'>reLi Energy GmbH will exclusively utilize the information provided here in strict adherence to the Privacy Policy.</div>
            </form>
            <div className={`careers-form-popup ${coverLetterOpen?'careers-form-coverletter-active':'careers-form-coverletter-inactive'}`}>
                <form className='careers-form-form-popup' onSubmit={handleSubmitCoverLetter} onReset={handleResetCoverLetter}>
                    <div className='careers-form-label content-small'>Cover Letter</div>
                    <textarea className='careers-form-input careers-form-input-multiline content-form'
                        type="text"
                        name="cover_letter"
                        rows="15"
                        value={formData.cover_letter}
                        onChange={handleChangeCoverLetter}
                        />
                    <button className='career-form-form-button-submit button button-primary' type="submit">Save</button>
                    <button className='career-form-form-button-submit button button-primary' type="reset">Close</button>
                </form>
            </div>
        </div>
        <div className={`careers-form ${applicationSubmitted?'careers-form-active':'careers-form-inactive'}`}>
            <div className='content'>Application has been successful subimtted!</div>
        </div>
        </>
    )
}

export default CareersForm;